<template>
  <el-main>
    <el-row class="body_img"></el-row>
    <el-row type="flex" justify="center">
      <el-col :span="17" class="bkg">
        <h1 class="big_title_fun">
          <img :src="url" style="margin-right: 10px; width: 40px" draggable="false" />
          {{ title }}
        </h1>
        <span style="color: #858690">{{ des }}</span>

        <el-upload
          class="upload-demo"
          auto-upload
          drag
          :show-file-list="true"
          :on-success="upload_file_success"
          :on-error="upload_file_error"
          :before-upload="loading"
          :data="appkey"
          action="https://svr.pdfgo.cc/updown/upload"
          :multiple="true"
          :on-remove="file_remove"
          :accept="upload_type"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <br />
            <el-button class="button-upload">上传</el-button>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </div>
        </el-upload>
        <el-button style="margin:30px" size="medium" type="success" @click="upload_success_fun">开始转换</el-button>
      </el-col>
    </el-row>



<el-divider></el-divider>
<div class="si">
<div class="b" v-if="
this.work_type == 'img_to_pdf'"><h3 style="border-left:5px solid #409EFF">图片转PDF的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把图片转换成PDF
</h3>
      <p class="func_desc">点击按钮或者图片拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量图片转换PDF模式
</h3>
      <p class="func_desc">优化转换图片转化PDF乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'merge_pdf'"><h3 style="border-left:5px solid #409EFF">PPT合并的功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把PDF合并
</h3>
      <p class="func_desc">点击按钮或者PDF拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>


<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量PDF合并模式
</h3>
      <p class="func_desc">优化转换PDF合并乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>

</div>
</div>




<div class="b" v-if="
this.work_type == 'img_changtu'"><h3 style="border-left:5px solid #409EFF">长图拼接功能介绍</h3>
<div style="margin-left: 100px;">
<ul class="func_list g_ul">
  <div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-question" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">如何把长图拼接
</h3>
      <p class="func_desc">点击按钮或者图片拉至拖放区，将文件上传至我们平台，文件转换完成后，您可以直接下载。</p>

    </div>

  </li>
  </div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-s-promotion" style="font-size: 50px;color:#409EFF; font-weight: 500"></i>
      <h3 class="func_title">高质量长图拼接模式
</h3>
      <p class="func_desc">优化转换长图拼接乱码，排版问题，为您提供优质的转换服务。</p>

    </div>

  </li>
</div>



<div class="lu">
  <li class="func_item">
    <div class="img">
      <i class="el-icon-lock" style="font-size: 50px; color:#409EFF;font-weight: 500"></i>
      <h3 class="func_title">尊重隐私，确保文件安全
</h3>
      <p class="func_desc">
100%保障您的隐私，可在我的转换记录中手动删除这些文件。</p>

    </div>
  </li>
</div>
</ul>
</div>
</div>
</div>





  </el-main>
</template>

<script>
import common from "./common";
import { Loading } from "element-ui";
import apiService from "@/api/service.js";
export default {
  name: "mybody",
  props: ["work_type"],
  mixins: [common],
  data() {
    return {
      loading_1: "",
      appkey: {
        appKey: "wxncvt90988"
      },
      upload_List: []
    };
  },
  methods: {
    file_remove(file, fileList) {
      console.log("file:"+file)
      let fileName = file.response.upFile.split("/")[1];
      this.upload_List.splice(this.upload_List.indexOf(fileName), 1);

    },
    loading(state) {
      this.loading_1 = Loading.service({});
    },
    upload_success_fun(response, fileList) {
      if (this.upload_List == "") {
        this.$message.error("请先上传文件");
      } else {
        sessionStorage.setItem("uploadFile", this.upload_List);
        let inPdfList = this.upload_List;//定义数组名
        this.$router.push("/done/" + this.work_type + '?file=' + inPdfList);
      }
    },
    upload_file_success(response, file, fileList) {
      this.loading_1.close();
      let infile = response.upFile;//改
      this.upload_List.push(infile);
      // console.log(this.upload_List);
    },
    upload_file_error(response, file, fileList) {
      this.loading_1.close();
      this.$message.error({
        message: "上传失败",
        center: true,
        offset: 120
      });
      console.log("上传失败");
    }
  }
};
</script>

<style scoped>
.el-main {
  background: #f1f3fe;
  color: #333;
  margin-top: 72px;
}

.body_img {
  height: 130px;
  background: url("~@/assets/content.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.bkg {
  color: #5a5b64;
  text-align: center;
  margin-top: -4px;
  height: 800px;
  background: white;
  border-radius: 20px;
}

.big_title_fun {
  font-size: 32px;
  padding-top: 36px;
  padding-bottom: 12px;
}

.upload-demo {
  /* width: 1000px; */
}

.button-upload {
  height: 40px;
  width: 190px;
  margin-top: 15px;
  background: #435bfd;
  color: white;
}
.el-button:hover {
  background: #7083ff;
}

.el-upload__text {
  font-size: 15px;
  padding: 20px;
}

/deep/ .el-upload-dragger {
  margin-top: 40px;
  min-height: 300px;
  min-width: 800px;
  background: linear-gradient(
180deg
,#f9fbff,#f2f7fe);
}


.lu {
  height: 300px;
  width: 300px;
  /* border: 1px solid red; */
  float: left;
  border-radius: 20px;
  text-align: center;
}

.si {
  width: 1000px;
  /* border: 1px solid red; */
  border-radius: 20px;
  /* text-align: center; */
  margin: 0 auto;
}

.b {
  width: 90%;
  /* border: 1px solid red; */
  /* margin-left: 100px; */
}
/* 文字说明 */
.func_list {
  margin: 22px -138px 0 0;
}
ul {
  list-style: none;
}

.g_ul > li {
  float: left;
}

.func_item {
  width: 238px;
  height: 235px;
  margin: 34px 138px 0 0;
  text-align: center;
  padding-left: 10px;
}

.func_list .img {
  height: 80px;
}

.func_title {
  margin-top: 15px;
  font-size: 18px;
  line-height: 30px;
  color: #333;
}
.func_desc {
  margin-top: 7px;
  font-size: 14px;
  line-height: 24px;
  color: #656565;
}
</style>
